* {
  font-family: "europa", sans-serif;
}

.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-moz-selection {
  /* Code for Firefox */
  color: inherit;
  background: #fff2d2;
}

::selection {
  color: inherit;
  background: #fff2d2;
}

.css-1iy78su {
  border-color: #a3aebd !important;
}

.swal-button {
  font-family: "Europa", sans-serif;
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #e7bd5b;
  font-size: 12px;
  color: #4a5568;
}

.swal-button:hover {
  background-color: #fff2d2;
}
